import React, { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import allCountries from 'utils/constants/countries';
import numeral from 'utils/numeral';
import { ACTIVATION, CUSTOMERS, GROUP } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text } from 'components/DataDisplay';
import { Table } from 'components/DataDisplay/Table';
import { FormSection } from 'components/FormSection';
import { Button, TextField, AutocompleteTextField, Switch } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';
import Loading from 'components/Loading';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import AddChildCustomer from './components/modalForm/AddChildCustomer';
import UpdateChildCustomer from './components/modalForm/updateChildCustomer';
import { CustomerActionsTypes, useCustomer } from './contexts/customContext';
import { CreateCustomerModal, EditableTable, DeleteCustomerModal } from './components';
import { isParent, sortByDate } from './utils';

export default () => {
  const [
    { customers, presets, countries, selectedCustomer, activableMedias },
    dispatchCustomer
  ] = useCustomer();
  const { adminRights } = useUser();
  const UNAUTHORIZED = 'unauthorized';
  const [customerInfo, setCustomerInfo] = useState({});
  const [subAccounts, setSubAccounts] = useState([]);
  const [constraint, setConstraint] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedParent, setSelectedParent] = useState(null);
  const [numberOfGroupes, setNumberOfGroupes] = useState(0);
  const [parents, setParents] = useState();
  const [selectedSubAccounts, setSelectedSubAccounts] = useState([]);
  const [financialAdministrators, setFinancialAdministrators] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allFees, setAllFees] = useState([]);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [customLabel, setCustomLabel] = useState('')
  const [modal, setModal] = useState('');
  const { themeColors } = useTheme();

  const initContext = async () => {
    const allCustomers = await CUSTOMERS.get();
    const allGroups = await GROUP.getGroups({ customLabel: 1 });
    const allActivableMedias = await ACTIVATION.getActivableMedias();

    const filteredGroups = allGroups.filter(el =>
      allCustomers?.every(r => r.groupId !== el._id)
    );
    const allPresets = await ACTIVATION.getActivationPresets();

    allCustomers.map(customer => {
      const group = allGroups.find(r => r._id === customer.groupId);
      // eslint-disable-next-line no-param-reassign
      customer.group = group;
      return customer;
    });

    const sortedCustomers = sortByDate(allCustomers);
    const localSelectedCustomer = sortedCustomers[0];
    setCustomerInfo(localSelectedCustomer?.info);

    dispatchCustomer({
      type: CustomerActionsTypes.UPDATE_CUSTOMER,
      props: {
        presets: allPresets,
        customers: sortedCustomers,
        selectedCustomer: localSelectedCustomer,
        groups: filteredGroups,
        selectedGroup: allGroups.find(
          group => group._id === localSelectedCustomer?.groupId
        ),
        countries: allCountries.sort((a, b) => a.label.localeCompare(b.label)),
        activableMedias: allActivableMedias
      }
    });

    if (!allCustomers.length) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = value => {
    dispatchCustomer({
      type: CustomerActionsTypes.UPDATE_CUSTOMER,
      props: {
        selectedCustomer: value
      }
    });
    setCustomerInfo(value?.info || {});
  };

  const handleModal = (rigth, name) => {
    const modalName = adminRights.general.customers[rigth]
      ? name
      : UNAUTHORIZED;

      if (name === 'customers') {
        setCustomerInfo({});
      }
    setModal(modalName);
  };

  const getParent = async () => {
    const filteredParents = customers.reduce(
      (acc, curr) => {
        if (!curr?.parentId && curr._id !== selectedCustomer._id) {
          acc.push({
            key: curr._id,
            label: curr.info?.name
          });
        }
        return acc;
      },
      [{ key: null, label: 'Aucun' }]
    );

    const parent =
      customers.find(customer => customer._id === selectedCustomer.parentId) ||
      null;
    setParents(filteredParents);
    setSelectedParent({
      key: parent?._id || null,
      label: parent?.info?.name || 'Aucun'
    });
  };

  const getSubAccounts = async () => {
    const foundSubAccounts = customers.reduce((acc, curr) => {
      if (curr.parentId === selectedCustomer._id) {
        acc.push({
          ...curr,
          id: curr._id,
          name: curr.info?.name,
          displayedMargin: curr.margin?.rate
            ? numeral(curr.margin.rate).format('0,0%')
            : 'N/A',
          subscription:
            curr.financialContribution.length > 0 ? (
              curr.financialContribution.map(fc => {
                return (
                  <Item
                    flex
                    justify="flex-start"
                    style={{
                      justifyContent: 'left',
                      flexWrap: 'nowrap'
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: 700,
                        marginRight: '10px'
                      }}
                    >
                      {activableMedias.find(media => media.key === fc.media)
                        ?.name || ''}
                    </Text>
                    <Text>{numeral(
                      fc.rate
                    ).format(
                      '0,0%'
                    )}</Text>
                  </Item>
                );
              })
            ) : (
              <>Par défaut</>
            )
        });
      }
      return acc;
    }, []);

    const subCustommers = await Promise.all(
      foundSubAccounts.map(async customer => {
        const balance = await CUSTOMERS.getBalance(customer.id);
        return { ...customer, balance: numeral(balance).format('0,0[.]00$') };
      })
    );

    setSubAccounts(subCustommers);
  };

  const getNumberOfGroupes = async presetId => {
    const numberOfGroupesResult = await GROUP.getGroups({
      presetId
    });
    setNumberOfGroupes(numberOfGroupesResult.length || 0);
  };

  const getFinancialAdmins = async () => {
    const usersByGroupId = await CUSTOMERS.getUsersByGroupid(
      selectedCustomer.groupId
    );

    const financialAdmins =
      selectedCustomer?.financialAdmins?.reduce((acc, curr) => {
        const user = usersByGroupId.find(u => u._id === curr);
        if (user) {
          acc.push(user);
        }
        return acc;
      }, []) || [];

    setAllUsers(usersByGroupId);
    setFinancialAdministrators(financialAdmins);
  };

  const getPresetData = async presetId => {
    const channels = await ACTIVATION.getConstraintsChannels(presetId);
    const fees = await ACTIVATION.getFees(presetId);
    const constraintResult = await ACTIVATION.getConstraints(presetId);
    const groupedChannelsByFee = channels.reduce((acc, curr) => {
      const { fee, channel } = curr;
      const { technicalFees, setUpFees, weeklyFees, dataCpm } = fees.find(
        f => f.id === fee
      );
      if (!acc[fee]) {
        acc[fee] = [];
      }
      acc[fee].push({
        fee,
        channel,
        technicalFees,
        setUpFees,
        weeklyFees,
        dataCpm
      });
      return acc;
    }, {});

    const mapped = Object.entries(groupedChannelsByFee).map(([key, value]) => {
      const {
        technicalFees,
        setUpFees,
        weeklyFees,
        dataCpm
      } = groupedChannelsByFee[key][0];
      return {
        _id: key,
        id: key,
        channels: value.map(v => v.channel).join(', '),
        technicalFees: numeral(technicalFees).format('0%'),
        setUpFees,
        weeklyFees,
        dataCpm
      };
    });
    setSelectedPreset({
      key: presetId,
      name: presets.find(p => p._id === presetId)?.name
    });
    setConstraint(constraintResult[0]);
    setAllFees(mapped);
  };

  const getGroupCustomLabel = async id => {
    const groupCustomLabel = await GROUP.getGroups({
      _id: id,
      customLabel: 1
    });
    setCustomLabel(groupCustomLabel[0]?.customLabel);
  }

  const initializeCutomerData = async () => {
    if (customers.length) {
      setIsLoading(true);
      await getParent();
      await getSubAccounts();

      const presetId = await CUSTOMERS.getPresetId(selectedCustomer?.groupId);

      await getNumberOfGroupes(presetId);
      await getFinancialAdmins();
      await getGroupCustomLabel(selectedCustomer?.groupId)
      await getPresetData(presetId);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (customers.length && selectedCustomer) {
      initializeCutomerData();
    }
    else {
      setCustomerInfo({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers, selectedCustomer]);

  const updateCustomer = async () => {
    if (!adminRights.general?.customers?.update) {
      setModal(UNAUTHORIZED);
      return;
    }

    const body = {
      ...selectedCustomer,
      parentId: selectedParent?.key,
      financialAdmins: financialAdministrators.map(admin => admin._id),
      info: customerInfo
    };

    const result = await Promise.all([
      CUSTOMERS.updateCostumers(selectedCustomer._id, body),
      GROUP.updateV2(selectedCustomer.groupId, {
        presetId: selectedPreset?.key
      }),
      ACTIVATION.updateManyFees(
        allFees.map(fee => ({
          _id: fee._id,
          technicalFees: numeral(fee.technicalFees).value(),
          setUpFees: fee.setUpFees,
          weeklyFees: fee.weeklyFees,
          dataCpm: fee.dataCpm
        }))
      ),
      ACTIVATION.updateConstraints(constraint._id, {
        maximumDataCost: constraint?.maximumDataCost
      })
    ]);

    const newCustomers = customers.map(customer => {
      if (customer._id === selectedCustomer._id) {
        return {
          ...customer,
          ...result[0],
          parentId: selectedParent?.key
        };
      }
      return customer;
    });
    dispatchCustomer({
      type: CustomerActionsTypes.UPDATE_CUSTOMER,
      props: {
        customers: newCustomers,
        selectedCustomer: {
          ...selectedCustomer,
          ...result[0],
          parentId: selectedParent?.key
        }
      }
    });
  };

  const handleFeesChange = (e, index, key) => {
    const newFees = [...allFees];
    newFees[index][key] = e;
    setAllFees(newFees);
  };

  const handleCheck = items => {
    setSelectedSubAccounts(
      items.map(id => {
        return customers.find(c => c._id === id);
      })
    );
  };

  const handleOpenUpdateChild = (rigth, name, rows) => {
    if (!adminRights.general?.customers[rigth]) {
      setModal(UNAUTHORIZED);
      return;
    }
    setSelectedSubAccounts(
      rows.map(row => {
        return customers.find(c => c._id === row.id);
      })
    );
    setModal(name);
  };

  const handleChangePreset = async presetId => {
    const numberOfGroupesResult = await GROUP.getGroups({
      presetId
    });
    setNumberOfGroupes(numberOfGroupesResult.length || 0);
    await getPresetData(presetId);
  };

  const removeChild = async item => {
    await CUSTOMERS.updateCostumers(item.id, {
      parentId: ''
    });
    if (!adminRights?.general?.customers?.delete) {
      setModal(UNAUTHORIZED);
      return;
    }
    await CUSTOMERS.updateCostumers(item.id, {
      parentId: ''
    });

    const newCustomers = customers.map(customer => {
      if (customer.id === item._id) {
        return { ...customer, parentId: '' };
      }
      return customer;
    });

    dispatchCustomer({
      type: CustomerActionsTypes.UPDATE_CUSTOMER,
      props: {
        customers: newCustomers
      }
    });
    getSubAccounts();
  };

  const checkFields = () => {
    return (
      !customerInfo?.name ||
      !customerInfo?.address?.country ||
      !customerInfo?.companyId ||
      !customerInfo?.address?.city ||
      !customerInfo?.address?.country
    );
  };

  const deleteCustomer = async () => {
    try {
      await CUSTOMERS.deleteCustomer(selectedCustomer._id);
      const updatedCustomers = customers.filter(customer => customer._id !== selectedCustomer._id);

      dispatchCustomer({
        type: CustomerActionsTypes.UPDATE_CUSTOMER,
        props: {
          selectedCustomer: updatedCustomers.length ? updatedCustomers[0] : null,
        }
      });
      await initContext(); 
      setModal('');
    } catch (error) {
      console.error("Erreur lors de la suppression du client", error);
    }
  };

  const canDelete = adminRights?.general?.customers?.advanced;

  return (
    <Loading loading={isLoading}>
      <Container>
        {modal === UNAUTHORIZED && (
          <UnauthorizedModal open onClose={() => setModal('')} />
        )}
        {modal === 'childcustomers' && (
          <UpdateChildCustomer
            selectedSubAccounts={selectedSubAccounts}
            open
            onClose={() => {
              setModal('');
            }}
          />
        )}
        {modal === 'delete' && (
          <DeleteCustomerModal
            open
            onClose={() => setModal('')}
            onConfirm={deleteCustomer}
            title="Suppression de compte client"
            description={`Après cette manipulation, le compte client : ${selectedCustomer?.info?.name} n'existera plus et les utilisateurs ne pourront plus lancer de campagne ou d'enrichissements CRM `}
          />
        )}
        {modal === 'customers' && (
          <CreateCustomerModal
            open
            onClose={() => {
              setModal('');
            }}
          />
        )}
        {modal === 'subaccounts' && (
          <AddChildCustomer
            open
            onClose={() => {
              setModal('');
            }}
          />
        )}
        {customers.length === 0 ? (
          <Row>
            <Item>
              <Text variant="h5" fontWeight={700}>
                Pour créer un client, cliquer sur le boutton ci-dessous
              </Text>
            </Item>
            <Item justify="center">
              <ButtonCreate
                justify="center"
                onClick={() => handleModal('create', 'customers')}
                text="Créer un Compte"
              />
            </Item>
          </Row>
        ) : (
          <>
            <Row justify="flex-end">
              <Item flex>
                <ButtonCreate
                  onClick={() => handleModal('create', 'customers')}
                  text="Ajouter un compte"
                />
              </Item>
            </Row>
            <Row>
              <Item justify="flex-start">
                <Container>
                  <Row justify="flex-start">
                    <Item xs={4} justify="flex-start">
                      <AutocompleteTextField
                        onChange={handleSelect}
                        options={customers}
                        formatLabel={option => option?.info?.name}
                        title="Compte"
                        value={selectedCustomer}
                      />
                    </Item>
                    <Item xs={4} justify="flex-start">
                      <AutocompleteTextField
                        onChange={e => {
                          setSelectedParent({
                            key: e?.key,
                            label: e?.label
                          });
                        }}
                        toolTipText={
                          isParent(selectedCustomer, customers)
                            ? `${selectedCustomer?.info?.name} est dejà parent`
                            : null
                        }
                        disabled={isParent(selectedCustomer, customers)}
                        options={parents}
                        formatLabel={option => option?.label}
                        value={selectedParent}
                        title="Compte Parent"
                      />
                    </Item>
                  </Row>
                  <Row justify="flex-start">
                    <Item xs={6} justify="flex-start">
                      <Row justify="flex-start">
                        <Text style={{ whiteSpace: 'nowrap' }}>
                          {"Liens: client (nom) < organisation (nom) > PML groupements / magasins (key) "}
                        </Text>
                        {customLabel?.length ? (
                          <Text style={{ whiteSpace: 'nowrap' }}>
                            {customLabel}
                          </Text>
                        ) : (
                          <Text>Aucun</Text>
                        )}
                      </Row>
                    </Item>
                  </Row>
                </Container>
              </Item>
            </Row>

            <Row>
              <Item justify="flex-start">
                <FormSection title="Conditions tarifaires" xs={3}>
                  <Container>
                    <Item justify="flex-start">
                      <Row justify="flex-start" alignItems="center">
                        <Item xs={4} justify="flex-start">
                          <AutocompleteTextField
                            onChange={e => {
                              handleChangePreset(e?.id);
                            }}
                            options={presets}
                            formatLabel={option => option?.name}
                            value={selectedPreset}
                            title="Preset appliqué"
                          />
                        </Item>
                        <Item xs={3} justify="flex-start">
                          <Text
                            color={themeColors.themeV2.notValidated}
                            italic
                            style={{
                              marginTop: '40px',
                              marginLeft: '20px'
                            }}
                          >
                            partagé avec {numberOfGroupes} autres organisations
                          </Text>
                        </Item>
                        <Item xs={4} justify="flex-start">
                          <TextField
                            style={{
                              marginLeft: 'auto'
                            }}
                            value={numeral(
                              constraint?.maximumDataCost || 0
                            ).format('0%')}
                            onChange={e => {
                              setConstraint({
                                ...constraint,
                                maximumDataCost: numeral(e).value()
                              });
                            }}
                            title="Frais data maximums"
                          />
                        </Item>
                      </Row>
                    </Item>
                    <Item
                      justify="flex-start"
                      style={{
                        paddingRight: '40px',
                        paddingLeft: '40px'
                      }}
                    >
                      <EditableTable
                        headers={[
                          { key: 'channels', label: 'Canaux' },
                          {
                            key: 'technicalFees',
                            label: 'Frais techniques',
                            editable: true
                          },
                          {
                            key: 'setUpFees',
                            label: 'Frais de set up',
                            editable: true
                          },
                          {
                            key: 'weeklyFees',
                            label: 'Frais de trading',
                            editable: true
                          },
                          {
                            key: 'dataCpm',
                            label: 'CPM data',
                            editable: true
                          }
                        ]}
                        values={allFees}
                        handleChange={(e, index, key) =>
                          handleFeesChange(e, index, key)
                        }
                      />
                    </Item>
                  </Container>
                </FormSection>
              </Item>
            </Row>
            <Row>
              <Item justify="flex-start">
                <FormSection title="Informations de facturation" xs={3}>
                  <Container>
                    <Row justify="flex-start">
                      <Item xs={4} justify="flex-start">
                        <TextField
                          required
                          error={!customerInfo?.companyId}
                          value={customerInfo?.companyId || ''}
                          onChange={e => {
                            setCustomerInfo({
                              ...customerInfo,
                              companyId: e
                            });
                          }}
                          title="SIRET"
                        />
                      </Item>
                      <Item xs={4} justify="flex-start">
                        <AutocompleteTextField
                          required
                          onChange={e => {
                            setCustomerInfo({
                              ...customerInfo,
                              address: {
                                ...customerInfo.address,
                                country: e.key
                              }
                            });
                          }}
                          error={!customerInfo?.address?.country}
                          options={countries}
                          formatLabel={option => option?.label}
                          value={countries.find(
                            c => c.key === customerInfo?.address?.country
                          ) || null}
                          title="Pays ou région"
                        />
                      </Item>
                    </Row>
                    <Row justify="flex-start">
                      <Item xs={4} justify="flex-start">
                        <TextField
                          required
                          error={!customerInfo?.name}
                          value={customerInfo?.name || ''}
                          onChange={e => {
                            setCustomerInfo({
                              ...customerInfo,
                              name: e
                            });
                          }}
                          title="Raison sociale"
                        />
                      </Item>
                      <Item xs={4} justify="flex-start">
                        <TextField
                          required
                          error={!customerInfo?.address?.line1}
                          value={customerInfo?.address?.line1 || ''}
                          onChange={e => {
                            setCustomerInfo({
                              ...customerInfo,
                              address: {
                                ...customerInfo.address,
                                line1: e
                              }
                            });
                          }}
                          title="Adresse (ligne 1)"
                        />
                      </Item>
                    </Row>
                    <Row justify="flex-start">
                      <Item xs={4} justify="flex-start">
                        <TextField
                          value={customerInfo?.address?.line2 || ''}
                          onChange={e => {
                            setCustomerInfo({
                              ...customerInfo,
                              address: {
                                ...customerInfo.address,
                                line2: e
                              }
                            });
                          }}
                          title="Adresse (ligne 2)"
                        />
                      </Item>
                      <Item xs={4} justify="flex-start">
                        <TextField
                          value={customerInfo?.address?.postalCode || ''}
                          onChange={e => {
                            setCustomerInfo({
                              ...customerInfo,
                              address: {
                                ...customerInfo.address,
                                postalCode: e
                              }
                            });
                          }}
                          title="Code postal"
                        />
                      </Item>
                    </Row>
                    <Row justify="flex-start">
                      <Item xs={4} justify="flex-start">
                        <TextField
                          error={!customerInfo?.address?.city}
                          value={customerInfo?.address?.city || ''}
                          onChange={e => {
                            setCustomerInfo({
                              ...customerInfo,
                              address: {
                                ...customerInfo.address,
                                city: e
                              }
                            });
                          }}
                          title="Ville"
                          required
                        />
                      </Item>
                      <Item xs={4} justify="flex-start">
                        <TextField
                          value={customerInfo?.address?.state || ''}
                          onChange={e => {
                            setCustomerInfo({
                              ...customerInfo,
                              address: {
                                ...customerInfo.address,
                                state: e
                              }
                            });
                          }}
                          title="Région"
                        />
                      </Item>
                    </Row>
                    <Row justify="flex-start">
                      <Item xs={4} justify="flex-start">
                        <TextField
                          value={customerInfo?.euVatNumber || ''}
                          onChange={e => {
                            setCustomerInfo({
                              ...customerInfo,
                              euVatNumber: e
                            });
                          }}
                          title="Numéro de TVA intracommunautaire"
                        />
                      </Item>
                      <Item xs={4} justify="flex-start">
                        <Switch
                          value={selectedCustomer?.activablePurchaseOrder}
                          onChange={e => {
                            dispatchCustomer({
                              type: CustomerActionsTypes.UPDATE_CUSTOMER,
                              props: {
                                selectedCustomer: {
                                  ...selectedCustomer,
                                  activablePurchaseOrder: e
                                }
                              }
                            });
                          }}
                          disabled={typeof selectedCustomer?.activablePurchaseOrder !== 'boolean'}
                          checked={selectedCustomer?.activablePurchaseOrder}
                          color={themeColors.primary}
                          title="Bon de commande"
                        />
                      </Item>
                    </Row>
                  </Container>
                </FormSection>
              </Item>
            </Row>
            <Row>
              <Item>
                <FormSection title="Administrateurs financiers" xs={3}>
                  <Container>
                    <Item xs>
                      <ListField
                        options={allUsers}
                        searchOptionsKeys={['email']}
                        values={financialAdministrators}
                        onChange={setFinancialAdministrators}
                        actionTxt="Ajouter un administrateur"
                        selectNbMax={1}
                        deleteAction
                      />
                    </Item>
                  </Container>
                </FormSection>
              </Item>
            </Row>
            <Row>
              {(isParent(selectedCustomer, customers) ||
                !selectedCustomer?.parentId) && (
                  <Item>
                    <FormSection title="Sous-comptes" xs={3}>
                      <Container>
                        <Row>
                          <Item justify="flex-start">
                            <Text fontSize="14px" fontWeight={400}>
                              Sélectionnez ci-dessous les sous-comptes auxquels
                              vous voulez appliquer un prélèvement et de l’abondement.
                            </Text>
                          </Item>
                        </Row>
                        <Row>
                          <Item xs>
                            <ButtonCreate
                              onClick={() => handleModal('create', 'subaccounts')}
                              text="Ajouter un compte"
                              justify="flex-start"
                            />
                          </Item>
                        </Row>
                        <Row>
                          <Item>
                            {subAccounts.length > 0 && (
                              <Table
                                headers={[
                                  {
                                    id: 'name',
                                    label: 'Sous-compte'
                                  },
                                  {
                                    id: 'balance',
                                    label: 'Solde'
                                  },
                                  {
                                    id: 'subscription',
                                    label: 'Abondement',
                                    help:
                                      "L'abondement s'applique uniquement sur les campagnes prêtes à l'emploi"
                                  },
                                  {
                                    id: 'displayedMargin',
                                    label: 'Prélévement'
                                  },
                                  {
                                    id: 'actions',
                                    label: (
                                      <Button
                                        variant="contained"
                                        color={themeColors.themeV2.backoffice}
                                        onClick={() =>
                                          handleModal('update', 'childcustomers')
                                        }
                                        disabled={!selectedSubAccounts.length}
                                      >
                                        <Text color="white">
                                          Modifier le prélèvement et l&apos;abondement
                                        </Text>
                                      </Button>
                                    )
                                  }
                                ]}
                                showCheckboxes
                                rows={subAccounts}
                                skipSlice
                                actions={[
                                  {
                                    key: 'edit',
                                    icon: <EditIcon />,
                                    onClick: row =>
                                      handleOpenUpdateChild(
                                        'update',
                                        'childcustomers',
                                        [row]
                                      ),
                                    display: () => true
                                  },
                                  {
                                    key: 'delete',
                                    icon: <DeleteIcon />,
                                    onClick: row => removeChild(row),
                                    display: () => true
                                  }
                                ]}
                                onCheck={handleCheck}
                              />
                            )}
                          </Item>
                        </Row>
                      </Container>
                    </FormSection>
                  </Item>
                )}
            </Row>
            <Row>
              <Item xs justify="flex-end">
                <Button
                  color="black"
                  onClick={updateCustomer}
                  variant="contained"
                  disabled={checkFields()}
                  disabledMessage="L’un des champs requis n’est pas renseigné"
                >
                  <SaveIcon />
                  &nbsp; Sauvegarder
                </Button>
              </Item>
              {canDelete && (
                <Item xs={6} justify="flex-start">
                  <Button
                    variant="contained"
                    color="red"
                    onClick={() => setModal('delete')}
                  >
                  <DeleteIcon /> Supprimer
                  </Button>
                  </Item>
                )}
            </Row>
          </>
        )}
      </Container>
    </Loading>
  );
};
